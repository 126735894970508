@import '~@which/seatbelt/src/styles/styles';

.articleListingAuthorHeader {
  color: sb-color('sb-color-black-100');
  padding-top: $sb-spacing-3xl;

  &Author {
    margin-bottom: $sb-spacing-m;
  }

  &Count {
    @include sb-font-size-line-height(17px, 25px);
    @include sb-font-medium;
    text-align: center;
    margin-bottom: $sb-spacing-m;
  }
}
